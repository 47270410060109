<template>
  <div class="flex p-[14px_16px] items-start gap-[14px] self-stretch w-[380px]">
    <!--  <span class="min-w-6 h-6 text-primary mt-0.5" v-html="$svg[icon]"></span> -->
    <span class="min-w-6 h-6 text-primary mt-0.5" v-html="$svg.speaker"></span>
    <div class="flex flex-col items-start gap-3">
      <div
        class="display: flex; flex-direction: column; align-items: flex-start; gap: 4px; align-self: stretch;"
      >
        <div
          class="text-[#151515] font-noto text-sm font-semibold leading-[20px]"
        >
          {{ title }}
        </div>
        <div
          class="text-[#7A7A7A] font-noto text-sm font-normal leading-[20px]"
        >
          <span>{{ description }}</span>
        </div>
      </div>
      <div class="flex items-start gap-4 self-stretch">
        <NuxtLink
          v-if="secondaryActionNuxtLink"
          :to="secondaryActionNuxtLink"
          class="cursor-pointer"
          ><div
            class="text-sm font-semibold text-[#7A7A7A] leading-5 font-noto cursor-pointer"
          >
            {{ secondaryActionName }}
          </div></NuxtLink
        >
        <div
          v-else
          class="text-sm font-semibold text-[#7A7A7A] leading-5 font-noto cursor-pointer"
        >
          {{ secondaryActionName }}
        </div>
        <NuxtLink
          v-if="primaryActionNuxtLink"
          :to="primaryActionNuxtLink"
          class="cursor-pointer"
          ><div
            class="text-sm font-semibold text-[#25478E] leading-5 font-noto cursor-pointer"
          >
            {{ primaryActionName }}
          </div></NuxtLink
        >
        <div
          v-else
          class="text-sm font-semibold text-[#25478E] leading-5 font-noto cursor-pointer"
        >
          {{ primaryActionName }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const { $svg } = useNuxtApp()

const props = withDefaults(
  defineProps<{
    icon?: string
    title?: string
    description?: string
    primaryActionName?: string
    secondaryActionName?: string
    primaryActionNuxtLink?: string
    secondaryActionNuxtLink?: string
  }>(),
  {
    icon: 'speaker',
    title: 'A new version of Transkribus is ready!',
    description:
      'We updated the software. Reload the page to get our latest release.',
    primaryActionName: 'Open Releasenote',
    primaryActionNuxtLink: '/changelog',
    secondaryActionName: 'Dismiss',
  }
)

const emit = defineEmits(['primaryClicked', 'secondaryClicked'])

const getLink = (notification: any) => {
  /*  /collection/22381/doc/50142?pageid=2 */
  const payload = notification.payload
  const link = `/collection/${payload?.collectionId}/doc/${payload?.docId}/edit?pageid=${payload?.pageId}`
  console.log(link)
  return link
}
</script>

<style scoped></style>
